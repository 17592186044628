var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{ref:"pageForm",attrs:{"title":_vm.title,"moda_title":"Manual payment general","items":_vm.items}}),_c('sister-round',{ref:"sisterRound",attrs:{"round":_vm.round,"rounds":_vm.rounds,"roundIdentifierNumber":_vm.roundIdentifierNumber}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('Tooltip',[_c('p',[_vm._v(" En este apartado podrás realizar pagos a todos los jugadores en una ronda en la cual haya ocurrido un error. ")]),_c('ol',[_c('li',[_vm._v(" Buscar por id de ronda (Este ID lo ve el jugador cuando está apostando, y en el reporte de rondas lo puedes identificar también.) ")]),_c('li',[_vm._v(" Podrás visualizar todas las rondas hermanas por un ID de ronda. ")]),_c('li',[_vm._v("Efectua el pago luego de haber verificado el error.")])])])],1),_c('div',{staticClass:"row mt-4"},[_c('b-col',{attrs:{"sm":"12","md":"3"}},[_c('div',{staticClass:"mb-3"},[_c('h5',[_vm._v(_vm._s(_vm.$t('generalPayment.search')))]),_c('vs-input',{staticClass:"shadow-lg",class:{
                    'is-invalid':
                      _vm.typesubmit && _vm.$v.typeform.identifierNumber.$error,
                  },attrs:{"placeholder":_vm.$t('transactions.roundID'),"type":"number","border":""},model:{value:(_vm.typeform.identifierNumber),callback:function ($$v) {_vm.$set(_vm.typeform, "identifierNumber", $$v)},expression:"typeform.identifierNumber"}}),(_vm.typesubmit && _vm.$v.typeform.identifierNumber.$error)?_c('div',{staticClass:"invalid-feedback"},[(_vm.$v.typeform.identifierNumber.required)?_c('span',[_vm._v(" Este campo es necesario. ")]):_vm._e(),(_vm.$v.typeform.identifierNumber.numeric)?_c('span',[_vm._v(" Este campo debe ser numerico. ")]):_vm._e()]):_vm._e(),_c('vs-button',{staticClass:"mt-3",attrs:{"type":"submit"},on:{"click":_vm.findRoundByIdentifierNumber}},[_vm._v(" "+_vm._s(_vm.$t('filter.search'))+" ")])],1)]),_c('div',{staticClass:"col-sm-12 col-md-12 d-inline-flex align-items-center"},[_c('div',{staticClass:"dataTables_length",attrs:{"id":"tickets-table_length"}},[_c('label',{staticClass:"d-inline-flex align-items-center m-0"},[_vm._v(" "+_vm._s(_vm.$t('filter.show'))+"  "),_c('vs-select',{staticClass:"shadow-lg",attrs:{"color":"dark"},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}},_vm._l((_vm.pageOptions),function(op,index){return _c('vs-option',{key:index,attrs:{"label":op,"value":op}},[_vm._v(_vm._s(op))])}),1),_vm._v("  "+_vm._s(_vm.$t('filter.entries'))+" ")],1)])])],1),(_vm.tableData.length)?_c('div',{staticClass:"row mt-2"},[_c('b-overlay',{attrs:{"rounded":"lg"}},[_c('b-table',{attrs:{"items":_vm.tableData,"fields":_vm.fields,"responsive":"sm","filter-included-fields":_vm.filterOn,"filter":_vm.filter,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},scopedSlots:_vm._u([{key:"cell(result)",fn:function(data){return [_c('span',{class:`text-light p-1  ${
                      data.item.result === -1
                        ? ''
                        : _vm.colorResult(data.item.result)
                    }`},[_vm._v(" "+_vm._s(_vm.result(data.item.result))+" ")])]}},{key:"cell(sisterRound)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('vs-button',{on:{"click":function($event){return _vm.lookSisterRound(data.item)}}},[_vm._v(" "+_vm._s(_vm.$t('helpers.look'))+" ")])],1)]}}],null,false,3617987160)})],1)],1):_vm._e()])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }